<template>
  <div>
    <div v-if="loading || !formLoaded">
      <span class="spinner"></span>
    </div>
    <div v-if="!loading && formLoaded">
      <b-form
        @submit.stop.prevent="onSubmit"
        :class="{
          'p-3 box-shadow2 rounded': !isDarkMode,
          'dark-div p-3 box-shadow2 rounded': isDarkMode
        }"
      >
        <b-row>
          <b-col sm="12" lg="6">
            <b-form-group id="group-name" label-for="input-name">
              <template v-slot:label>
                Event Name
                <span class="text-danger">*</span>
              </template>

              <b-form-input
                :class="{
                  'box-shadow3 bg-light text-dark': !isDarkMode,
                  'bg-dark text-light placeholder-light': isDarkMode
                }"
                id="input-name"
                type="text"
                v-model="form.name"
                placeholder="Enter Event Name"
                @keydown="nameKeydown($event)"
                :state="$v.form.name.$dirty ? !$v.form.name.$error : null"
              ></b-form-input>

              <b-form-invalid-feedback id="input-name-invalid">Please enter event name.</b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group id="group-product" label-for="input-product">
              <template v-slot:label>
                Active
                <span class="text-danger">*</span>
              </template>

              <multiselect
                id="input-status"
                placeholder="Select Active Type"
                v-model="form.active"
                :options="['True', 'False']"
                :state="$v.form.active.$dirty ? !$v.form.active.$error : null"
                :class="{
                  'box-shadow3': !isDarkMode,
                  darkMode: isDarkMode
                }"
              ></multiselect>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12" lg="6">
            <b-form-group id="group-products" label-for="input-products">
              <template v-slot:label>
                Select Date
                <span class="text-danger">*</span>
              </template>
              <b-row>
                <b-col>
                  <b-input-group class="box-shadow2 mb-2">
                    <b-input-group-append>
                      <b-form-datepicker
                        button-only
                        id="start-datepicker"
                        placeholder="Start Date"
                        :min="minDate"
                        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                        v-model="form.startDate"
                        class="mb-2 box-shadow3"
                      ></b-form-datepicker>
                    </b-input-group-append>
                    <b-form-input
                      id="example-input"
                      v-model="form.startDate"
                      type="text"
                      placeholder="Start Date"
                      autocomplete="off"
                      :class="{
                        '': !isDarkMode,
                        'bg-dark text-light placeholder-light': isDarkMode
                      }"
                      disabled
                    ></b-form-input>
                  </b-input-group>
                </b-col>
                <b-col>
                  <b-input-group class="box-shadow2 mb-2">
                    <b-input-group-append>
                      <b-form-datepicker
                        button-only
                        id="end-datepicker"
                        placeholder="End Date"
                        :min="minDate"
                        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                        v-model="form.endDate"
                        class="box-shadow3"
                      ></b-form-datepicker>
                    </b-input-group-append>
                    <b-form-input
                      id="example-input"
                      v-model="form.endDate"
                      type="text"
                      placeholder="End Date"
                      autocomplete="off"
                      :class="{
                        '': !isDarkMode,
                        'bg-dark text-light placeholder-light': isDarkMode
                      }"
                      disabled
                    ></b-form-input>
                  </b-input-group>
                </b-col>
              </b-row>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group id="group-frequency" label-for="input-frequency">
              <template v-slot:label> Frequency </template>

              <multiselect
                id="input-frequency"
                :options="['DAILY', 'WEEKLY', 'BIWEEKLY', 'MONTHLY']"
                v-model="form.frequency"
                placeholder="How Frequently report need to be submitted."
                :class="{
                  'box-shadow3 bg-light text': !isDarkMode,
                  darkMode: isDarkMode
                }"
              ></multiselect>
            </b-form-group>
          </b-col>
        </b-row>

        <p v-if="monthlyValidation" class="text-danger" id="input-role-help"
          >**Note: Can't create the event with start date 31st and frequency 'MONTHLY'</p
        >
        <b-row>
          <b-col sm="12" lg="6">
            <b-form-group id="group-products" label-for="input-products">
              <template v-slot:label>
                Store Format
                <span class="text-danger">*</span>
              </template>

              <multiselect
                ref="multiselect"
                tag-placeholder="Add this as new tag"
                track-by="value"
                :multiple="true"
                :taggable="true"
                @tag="addTag"
                v-model="form.formats"
                placeholder="Select Store Format"
                label="text"
                :options="formats"
                :class="{
                  'box-shadow3 bg-light text': !isDarkMode,
                  darkMode: isDarkMode
                }"
              >
              </multiselect>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group id="group-products" label-for="input-products">
              <template v-slot:label>
                Region Ids
                <span class="text-danger">*</span>
              </template>

              <multiselect
                ref="multiselect"
                tag-placeholder="Add this as new tag"
                track-by="value"
                :multiple="true"
                :taggable="true"
                @tag="addTag"
                v-model="regionids"
                placeholder="Select region Ids"
                label="text"
                :options="regions"
                :class="{
                  'box-shadow3 bg-light text': !isDarkMode,
                  darkMode: isDarkMode
                }"
              >
              </multiselect>
            </b-form-group>
          </b-col>
        </b-row>

        <b-form-group id="group-products" label-for="input-products">
          <template v-slot:label>
            Cluster Ids
            <span class="text-danger">*</span>
          </template>
          <multiselect
            ref="multiselect"
            tag-placeholder="Add this as new tag"
            track-by="value"
            :multiple="true"
            :taggable="true"
            @tag="addTag"
            v-model="clusterids"
            placeholder="Select Cluster Ids"
            label="text"
            :options="clusters"
            :class="{
              'box-shadow3 bg-light text': !isDarkMode,
              darkMode: isDarkMode
            }"
          >
          </multiselect>
        </b-form-group>

        <b-form-group id="group-products" label-for="input-products">
          <template v-slot:label>
            Store Ids
            <span class="text-danger">*</span>
          </template>
          <multiselect
            v-model="storeids"
            ref="multiselect"
            tag-placeholder="Add this as new tag"
            placeholder="Select Store Ids"
            label="text"
            track-by="value"
            :options="stores"
            :multiple="true"
            :taggable="true"
            @tag="addTag"
            :class="{
              'box-shadow3 bg-light text': !isDarkMode,
              darkMode: isDarkMode
            }"
          >
          </multiselect>
          <b-form-text id="input-role-help"
            >Note: The first selected store id will be the primary store id of CheckList for all
            operations.</b-form-text
          >
          <b-button class="box-shadow2" @click="uploadStores" :variant="isDarkMode ? 'outline-success' : 'success'"
            ><font-awesome-icon :icon="['fas', 'upload']" class="mr-1" />Upload Stores</b-button
          >
        </b-form-group>

        <b-form-group id="group-products" label-for="input-products">
          <template v-slot:label>
            Role
            <span class="text-danger">*</span>
          </template>

          <multiselect
            id="input-role"
            v-model="form.role"
            placeholder="Select role"
            label="text"
            ref="multiselect"
            track-by="value"
            :multiple="true"
            :taggable="true"
            @tag="addTag"
            :options="role"
            :class="{
              'box-shadow3 bg-light text': !isDarkMode,
              darkMode: isDarkMode
            }"
          ></multiselect>
        </b-form-group>

        <template v-if="storeIdError">
          <b-row class="mb-2">
            <b-col class="text-danger message-col">{{ storeIdError }}</b-col>
          </b-row>
        </template>
        <template v-if="regionIdError">
          <b-row class="mb-2">
            <b-col class="text-danger message-col">{{ regionIdError }}</b-col>
          </b-row>
        </template>
        <template v-if="clusterIdError">
          <b-row class="mb-2">
            <b-col class="text-danger message-col">{{ clusterIdError }}</b-col>
          </b-row>
        </template>

        <b-form-group>
          <!-- <div class="input wrapper items-center" v-for="(input, eventTask) in inputs" :key="eventTask">
            <b-row class="mb-3">
              <b-col>
                <b-row>
                  <b-col>
                    <template labelfor="input-category"> Task Name </template>

                    <b-form-input
                      id="input-taskName"
                      v-model="input.taskName"
                      placeholder="Enter Task Name"
                      type="text"
                      aria-describedby="input-live-help input-live-feedback"
                    ></b-form-input>
                    <b-form-invalid-feedback id="input-taskName-feedback">
                      Task Name is required.
                    </b-form-invalid-feedback>
                  </b-col>
                  <b-col>
                    <template labelfor="input-category"> Date </template>
                    <div class="date">
                      <b-form-datepicker
                        id="input-taskDate"
                        placeholder="Select Date"
                        :min="minDate"
                        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                        v-model="input.taskDate"
                        class="ml-2 mb-2"
                      ></b-form-datepicker>
                    </div>
                  </b-col>
                  <b-col sm="0">
                    <b-button
                      class="mt-4"
                      @click="remove(eventTask)"
                      v-show="eventTask || (!eventTask && inputs.length > 1)"
                      variant="danger"
                      >Delete</b-button
                    >
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-button @click="add(eventTask)" v-show="eventTask == inputs.length - 1" variant="primary"
              >Add Event Task</b-button
            >
          </div> -->

          <b-row class="mt-2">
            <b-col class="text-right">
              <b-button
                class="box-shadow2"
                type="submit"
                size="sm"
                :variant="isDarkMode ? 'outline-success' : 'success'"
                :disabled="loading"
              >
                <span class="spinner spinner-white" v-if="loading"></span>
                <font-awesome-icon :icon="['fas', 'save']" class="mr-1" />Save
              </b-button>
              <b-button
                class="ml-2 box-shadow2"
                size="sm"
                :variant="isDarkMode ? 'outline-warning' : 'warning'"
                :to="{ path: `/eventplanlist` }"
              >
                <font-awesome-icon :icon="['fas', 'long-arrow-alt-left']" class="mr-1" />Back to list
              </b-button>
            </b-col>
          </b-row>
        </b-form-group>
      </b-form>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import moment from 'moment';
import Vue from 'vue';
import csv from 'csvtojson';
import { mapGetters, mapState, mapActions } from 'vuex';
import configService from '@/services/configService';
import router from '@/router';
import Multiselect from 'vue-multiselect';

export default {
  name: 'EventFormBox',
  components: {
    Multiselect
  },
  props: {
    listUrl: String,
    formType: String,
    eventType: String
  },
  metaInfo() {
    return {
      meta: [
        {
          name: 'description',
          content: this.metaDescription
        }
      ]
    };
  },
  data() {
    return {
      inputs: [
        {
          name: ''
        }
      ],
      taskName: '',
      title: '',
      formLoaded: true,
      regions: [],
      clusters: [],
      formats: [],
      stores: [],
      role: [],
      storeids: [],
      clusterids: [],
      // eventTask: [],
      regionids: [],
      form: {
        name: null,
        active: 'True',
        startDate: null,
        endDate: null,
        storeids: [],
        regionids: [],
        formats: [],
        clusterids: [],
        role: [],
        frequency: 'DAILY'
        // eventTask: []
      },
      storeIdError: null,
      clusterIdError: null,
      regionIdError: null,
      dateTimeFormat: configService.get('format').pickerDateTime,
      minDate: moment().format('YYYY-MM-DD')
    };
  },
  validations() {
    const formValidation = {
      name: {
        required
      },
      active: {
        required
      }
    };

    if (this.formType === 'new') {
      formValidation.name.required = required;
      formValidation.active.required = required;
    }

    return { form: formValidation };
  },
  mounted() {
    this.listRole({ router });
    this.listStore({ router });
    this.listCluster({ router, query: { type: 'cluster' } });
    this.listRegion({ router, query: { type: 'region' } });
    this.listFormat({ router, query: { type: 'format' } });

    this.$nextTick(async () => {
      if (this.formType === 'new') {
        this.formLoaded = true;
        this.$v.$touch(); // Set initial validation
        this.$v.$reset(); // Reset $dirty
      }
    });
  },
  computed: {
    metaDescription() {
      return this.formType === 'new' ? 'Add new Event' : 'Update Event';
    },
    ...mapGetters('alert', ['errorMessages']),
    ...mapState('darkMode', ['isDarkMode']),
    ...mapState('role', ['roles']),
    ...mapState('events', ['loading', 'event']),
    ...mapState('store', ['storeList', 'regionList', 'clusterList', 'formatList']),
    monthlyValidation() {
      return this.form.frequency === 'MONTHLY' && moment(this.form.startDate).format('DD') === '31' ? true : false;
    }
  },
  methods: {
    ...mapActions('role', ['listRole']),
    ...mapActions('events', ['listEvents', 'getOne', 'postOne', 'patchOne']),
    ...mapActions('store', ['listStore', 'listRegion', 'listCluster', 'listFormat']),

    onSubmit() {
      if (this.monthlyValidation) {
        alert('Please select the start date of this event different that 31st');
        return;
      } else {
        this.storeIdError = null;
        this.$v.$touch();
        if (this.$v.$invalid) {
          return false;
        }
        const storeids = [];
        this.storeids.map(({ value }) => storeids.push(value));

        const regions = [];
        this.regionids.map(({ value }) => regions.push(value));

        const clusters = [];
        this.clusterids.map(({ value }) => clusters.push(value));

        const formats = [];
        this.form.formats.map(({ value }) => formats.push(value));

        const roles = [];
        this.form.role.map(({ value }) => roles.push(value));

        const event = {
          name: this.form.name,
          active: this.form.active === 'True',
          startDate: this.form.startDate,
          endDate: this.form.endDate,
          storeids,
          roles: roles || [],
          clusters: clusters || [],
          regions: regions || [],
          formats: formats || [],
          frequency: this.form.frequency || 'DAILY'
          // eventTask: this.inputs
        };
        if (this.formType === 'new') {
          this.$emit('add', { event });
        } else {
          this.$emit('edit', { event });
        }
        return false;
      }
    },
    createCsv() {
      const csv = `Store Id\n`;
      const anchor = document.createElement('a');
      anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
      anchor.target = '_blank';
      anchor.download = 'sampleFile.csv';
      anchor.click();
    },
    async uploadStores() {
      await Vue.swal({
        title: 'Upload file for Stores..',
        input: 'file',
        inputAttributes: {
          'aria-label': 'Upload your Store file in csv format'
        },
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Upload',
        denyButtonText: 'Download Sample',
        denyButtonColor: '#3a4',
        showCancelButton: true,
        showDenyButton: true,
        cancelButtonText: 'Close',
        background: this.isDarkMode ? '#464d54' : '#ffffff',
        customClass: {
          title: this.isDarkMode ? 'text-light' : 'text-dark',
          content: this.isDarkMode ? 'text-light' : 'text-dark'
        }
      }).then(result => {
        if (result.isDenied) {
          this.createCsv();
        }
        if (result.value) {
          const reader = new FileReader();
          reader.onload = async () => {
            csv({
              noheader: true,
              output: 'csv'
            })
              .fromString(reader.result)
              .then(csvRow => {
                const inputs = csvRow.slice(1, csvRow.length).map(item => {
                  if (item[0].length !== 4) {
                    Vue.swal({
                      title: 'Access Denied',
                      text: `Store Id ${item[0].charAt(0).toUpperCase() + item[0].slice(1)} must be 4 digits`,
                      type: 'error',
                      confirmButtonText: 'Ok',
                      background: this.isDarkMode ? '#464d54' : '#ffffff',
                      customClass: {
                        title: this.isDarkMode ? 'text-light' : 'text-dark',
                        content: this.isDarkMode ? 'text-light' : 'text-dark'
                      }
                    });
                    return null;
                  }
                  const inUppercase = item[0].toUpperCase();
                  const compareStoreIdFromDatabse = this.newStoreList.filter(store => store.storeid === inUppercase);
                  if (compareStoreIdFromDatabse.length === 0) {
                    Vue.swal({
                      title: 'Access Denied',
                      text: `Store id ${
                        item[0].charAt(0).toUpperCase() + item[0].slice(1)
                      } is not found in database! Please enter correct store id.`,
                      type: 'error',
                      confirmButtonText: 'Ok',
                      background: this.isDarkMode ? '#464d54' : '#ffffff',
                      customClass: {
                        title: this.isDarkMode ? 'text-light' : 'text-dark',
                        content: this.isDarkMode ? 'text-light' : 'text-dark'
                      }
                    });
                    return null;
                  }
                  return {
                    value: item[0].charAt(0).toUpperCase() + item[0].slice(1),
                    text: item[0].charAt(0).toUpperCase() + item[0].slice(1)
                  };
                });
                const uniqueStoreId = new Set(inputs.map(input => input.value));
                const getDuplicateValue = [...uniqueStoreId].filter(
                  item => inputs.filter(input => input.value === item).length > 1
                );
                if (getDuplicateValue.length > 0) {
                  Vue.swal({
                    title: 'Access Denied',
                    text: `Store id ${getDuplicateValue[0]} is duplicate in sample file!`,
                    type: 'error',
                    confirmButtonText: 'Ok',
                    background: this.isDarkMode ? '#464d54' : '#ffffff',
                    customClass: {
                      title: this.isDarkMode ? 'text-light' : 'text-dark',
                      content: this.isDarkMode ? 'text-light' : 'text-dark'
                    }
                  });
                  return null;
                }
                this.abc = inputs.map(store => ({
                  value: store.value,
                  text: store.value
                }));
                const newAddingStore = this.abc.map(store => store.value);
                const foundExistStore = this.storeids.filter(store => newAddingStore.includes(store.value));
                if (foundExistStore.length > 0) {
                  Vue.swal({
                    title: 'Access Denied',
                    text: `Store id ${foundExistStore[0].value} is already there!`,
                    type: 'error',
                    confirmButtonText: 'Ok',
                    background: this.isDarkMode ? '#464d54' : '#ffffff',
                    customClass: {
                      title: this.isDarkMode ? 'text-light' : 'text-dark',
                      content: this.isDarkMode ? 'text-light' : 'text-dark'
                    }
                  });
                  return null;
                }
                this.storeids = [...this.storeids, ...this.abc];
              });
          };
          reader.readAsBinaryString(result.value);
        }
      });
    },

    addTag(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000)
      };
      this.options.push(tag);
      this.value.push(tag);
      setTimeout(() => {
        this.$refs.multiselect.$refs.search.focus();
      }, 100);
    },
    nameKeydown(e) {
      if (/^[^a-zA-Z0-9 ]$/.test(e.key)) {
        e.preventDefault();
      }
    }
    // add() {
    //   this.inputs.push({ taskName: '', taskDate: this.date });
    // },
    // remove(index) {
    //   this.inputs.splice(index, 1);
    // }
  },
  watch: {
    name(val) {
      this.form.name = val.replace(/\W/g, '');
    },
    event(newValue) {
      if (!this.event.id) {
        return;
      }
      // Loaded event, assign to form
      this.form.name = newValue.name;
      this.form.active = newValue.active;
      this.form.checkpointType = newValue.checkpointType;
      this.form.isCritical = newValue.isCritical === 'True';
      this.form.startDate = moment(newValue.startDate).format('YYYY-MM-DD');
      this.form.endDate = moment(newValue.endDate).format('YYYY-MM-DD');
      // this.inputs = newValue.eventTask;
      this.storeids = newValue.storeids.map(store => ({ text: store, value: store }));
      this.regionids = newValue.regions.map(region => ({ text: region, value: region }));
      this.clusterids = newValue.clusters.map(cluster => ({ text: cluster, value: cluster }));
      this.form.role = newValue.roles.map(({ name, _id }) => ({ text: name, value: _id }));
      this.form.formats = newValue.formats.map(format => ({ text: format, value: format }));
      this.form.frequency = newValue.frequency;
      this.formLoaded = true;
      this.$v.$touch(); // Set initial validation
      this.$v.$reset(); // Reset $dirty
    },
    clusterList(newValue) {
      this.clusters = newValue;
    },
    regionList(newValue) {
      this.regions = newValue;
    },
    formatList(newValue) {
      this.formats = newValue;
    },
    roles(newValue) {
      this.role = newValue;
    },
    storeList(newValue) {
      let comparingStores = [];
      comparingStores = newValue.map(store => ({
        storeid: store.storeid
      }));
      this.newStoreList = comparingStores;
      this.stores = newValue.map(store => ({ text: store.storeid, value: store.storeid }));
    }
  }
};
</script>
<style src="vue-search-select/dist/VueSearchSelect.css"></style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style>
.calender {
  display: flex;
  width: auto;
  margin-left: -9px;
  margin-bottom: 0.7rem;
}
.date {
  margin-left: -10px;
}
.upload {
  margin-top: 8px;
}
</style>
